export const Sun = () => (
  <svg
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    stroke='#FF8C00'
    height='4.5rem'
    width='4.5rem'
  >
    <g id='SVGRepo_bgCarrier' stroke-width='0'></g>
    <g
      id='SVGRepo_tracerCarrier'
      stroke-linecap='round'
      stroke-linejoin='round'
    ></g>
    <g id='SVGRepo_iconCarrier'>
      {' '}
      <circle
        cx='12'
        cy='12'
        r='5'
        stroke='#FF8C00'
        stroke-width='1.5'
      ></circle>{' '}
      <path
        d='M12 2V4'
        stroke='#FF8C00'
        stroke-width='1.5'
        stroke-linecap='round'
      ></path>{' '}
      <path
        d='M12 20V22'
        stroke='#FF8C00'
        stroke-width='1.5'
        stroke-linecap='round'
      ></path>{' '}
      <path
        d='M4 12L2 12'
        stroke='#FF8C00'
        stroke-width='1.5'
        stroke-linecap='round'
      ></path>{' '}
      <path
        d='M22 12L20 12'
        stroke='#FF8C00'
        stroke-width='1.5'
        stroke-linecap='round'
      ></path>{' '}
      <path
        opacity='0.5'
        d='M19.7778 4.22266L17.5558 6.25424'
        stroke='#FF8C00'
        stroke-width='1.5'
        stroke-linecap='round'
      ></path>{' '}
      <path
        opacity='0.5'
        d='M4.22217 4.22266L6.44418 6.25424'
        stroke='#FF8C00'
        stroke-width='1.5'
        stroke-linecap='round'
      ></path>{' '}
      <path
        opacity='0.5'
        d='M6.44434 17.5557L4.22211 19.7779'
        stroke='#FF8C00'
        stroke-width='1.5'
        stroke-linecap='round'
      ></path>{' '}
      <path
        opacity='0.5'
        d='M19.7778 19.7773L17.5558 17.5551'
        stroke='#FF8C00'
        stroke-width='1.5'
        stroke-linecap='round'
      ></path>{' '}
    </g>
  </svg>
);
